<template>
	<div class="container-fluid mt-5 p-4">
		<div class="row">
			<div class="col-md-12 col-12 col-sm-12 col-lg-6">
				<h1>{{profile.name !== '' ? profile.name : profile.wallet_addr}}</h1>
				<PlayerCard :profile="profile"></PlayerCard>
				
				<h1 class="">Pets</h1>
				<PetList class=""></PetList>
			</div>
			<div class="col-12 col-md-12 col-lg-6 row">
				<div class="col-sm-12 col-md-4 col-lg-4">
					<h1>Skin <small>
						<button class="btn-none btn-sm">EDIT</button></small></h1>
					<PlayerSprite v-if="user.sprite_uri !== ''" :user="profile"></PlayerSprite>
				</div>
				<div class="col-sm-12 col-md-8 col-lg-8">
					<h1 class="mb-3">Equipment</h1>
					<Equipment :user="user"></Equipment>
				</div>
			</div>
		</div>
		<div class="row mt-3">
			<h1>Inventory</h1>
			<Inventory :user="user"></Inventory>
		</div>
	</div>
</template>

<script>
	import User from "@/api/user"
	import PlayerCard from "../../components/profile/PlayerCard";
	import Inventory from "../../components/profile/Inventory";
	import Equipment from "../../components/profile/Equipment";
	import PetList from "../../components/profile/PetList";
	import PlayerSprite from "../../components/profile/PlayerSprite";
	
	export default {
		name: "PublicProfile",
		components: {PlayerSprite, PetList, Equipment, Inventory, PlayerCard},
		data() {
			return {
				user: "",
				profile: {}
			}
		},
		methods: {
			getProfile: function () {
				User.show(this.$route.params.id).then(resp => {
					this.profile = resp.data;
				})
			}
		},
		mounted() {
			this.getProfile();
		}
	}
</script>

<style scoped>
	.fill-height {
		height: 100%;
	}
</style>